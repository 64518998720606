import React, {useContext, useRef} from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from 'react-router-dom';
import APIEndpoints from '../config';
import {GlobalContext} from './GlobalContext.tsx';
import { useState } from 'react';

interface IFormWelcomeLogin {
    accountEmail: string;
    accountPassword: string;
  }

export function FormLogin(){
    const { register, handleSubmit, formState: {errors}, setError } = useForm<IFormWelcomeLogin>();
    const [welcomeText,setWelcomeText]  = useState(null);
    const isWelcomeLoading              = useRef(false);
    const [isSubmitting,setIsSubmitting] = useState(false);
    const {globalDispatch} = useContext(GlobalContext);

    if (welcomeText===null&&isWelcomeLoading.current===false){
      isWelcomeLoading.current=true;
      fetch(APIEndpoints.welcomeUrlText,{method:'get',mode:'cors',headers:{
        'Accept': 'application/json',
        "X-Requested-With": "XMLHttpRequest",
      }}).then(response => response.json())
         .then(data => {
          isWelcomeLoading.current=false;
          setWelcomeText(data.welcomePageText);
      }).catch(error => console.error(error));
    }

    const onSubmit: SubmitHandler<IFormWelcomeLogin> = (e) => {
      setIsSubmitting(true);
      fetch(APIEndpoints.login,{
        method:'POST',mode:'cors',
        body:JSON.stringify(e),
        headers: {
          'Accept': 'application/json',
          "X-Requested-With": "XMLHttpRequest",
      },})
        .then(response => response.json())
        .then(data => {
          setIsSubmitting(false);
          if (data.messages){
            Object.keys(data.messages).map((k,i) => {
              Object.keys(data.messages[k]).map((m) => {
                setError(k, { type: "validate", message: data.messages[k][m] })
              });
            });
          }
          if (data.flashMessages){
            globalDispatch({type:'ADD_MESSAGES',flashMessages:data.flashMessages});
          }
          if(data.goto!==undefined){
            window.location.replace(data.goto);
          }
        })
        .catch(error => console.error(error));
    };

    // let currentDate = new Date();
    // let promoDate = new Date(2024,9,22,23,59,59,0);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <input disabled={isSubmitting}  type="email" className="form-control" id="loginUserEmail" placeholder="moj@adres.pl"  {...register("accountEmail", { required: true })} />
            <label htmlFor="loginUserEmail">Adres email</label>
            <div className="invalid-feedback">{errors.accountEmail && errors.accountEmail.message}</div>
          </div>
          <div className="form-floating mb-3">
            <input disabled={isSubmitting}  type="password" className="form-control" id="loginUserPassword" placeholder="Hasło do logowania" {...register("accountPassword", { required: true })} />
            <label htmlFor="loginUserPassword">Hasło</label>
            <div className="invalid-feedback">{errors.accountPassword && errors.accountPassword.message}</div>
          </div>
    
          <div className="d-grid">
            <button className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Zaloguj się
            </button>
            <div className="text-center">
              <Link to={'/nowe-haslo'} className="small btn btn-xs">Przypomnieć hasło?</Link> |  <Link to={'/nowe-konto'} className="small btn btn-xs">Nie masz konta?</Link>
            </div>
          </div>
          <div className="text-center my-2">
            <Link to={'/dlaczego-warto'} className="small btn btn-xs  btn-primary fw-bold mb-2 ">Dlaczego warto?</Link>
          </div>
          {welcomeText!==null? <div className="alert alert-info mt-3" role="alert" dangerouslySetInnerHTML={{__html:welcomeText}}></div> : null }
        </form>
      );
}