import * as React from "react";
//import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import APIEndpoints from "../config.jsx";


interface recordProductPrice{
  ID:number;
  ProductID:number;
  Title:string;
  Content:string;
  Price:number;
  TimeDelta:string;
}

interface recordProduct{
  ID:number;
  Title:string;
  Content:string;
  SpecID:number;
  SpecName:string;
  Prices:recordProductPrice[]|null;
}

function DrawSpecPrice({item}:{item:recordProductPrice}){
  return <>
    <div className="col">
      <div className="card mb-4 rounded-2 shadow-sm">
        <div className="card-header py-2 text-white" style={{backgroundColor:'#f9844a'}}>
          <h3 className="my-0 fw-normal">{item.Title}</h3>
        </div>
        <div className="card-body">
            <h1 className="card-title pricing-card-title">{item.Price} <small className="text-body-secondary fw-light">zł</small></h1>
            <div dangerouslySetInnerHTML={{__html:item.Content}} />
        </div>
      </div>
    </div>
  </>
}

export default function DlaczegoWartoPage(){
    let [product,setProduct] = React.useState<recordProduct[]|null|'loading'>(null);
    let [selected,setSelected] = React.useState<number|null>(null);
    let [isLoadingPrices,setIsLoadingPrices] = React.useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Dlaczego warto? - ZdajLEKko.pl';
      },[]);

    useEffect(()=>{
      // console.log('effect?');
      // console.log({selected:selected,product:product,isLoadingPrices:isLoadingPrices});
      if (product!=='loading'&&product!==null&&selected!==null&&isLoadingPrices===false){

        let currentOffer = product.find(x=>x.ID===selected);

        if (currentOffer?.Prices===null){
          setIsLoadingPrices(true);
          fetch(APIEndpoints.offerPrices,{
            method:'POST',mode:'cors',
            body : JSON.stringify({ID:selected}),
            headers: {
              'Accept': 'application/json',
              "X-Requested-With": "XMLHttpRequest",
            }
          }).then(r=>r.json())
          .then(d=>{
            let copy = product.map(function(i){
              if (i.ID===selected){
                i.Prices = d.data;
              }
              return i;
            });
            setIsLoadingPrices(false);
            setProduct(copy);
          }).catch(e=>console.error(e));
        }
      }
    },[selected,product,isLoadingPrices]);

    if (product===null){
      setProduct('loading');
      fetch(APIEndpoints.offer,{
        method:'GET',mode:'cors',
        headers: {
          'Accept': 'application/json',
          "X-Requested-With": "XMLHttpRequest",
      },})
        .then(response => response.json())
        .then(data => {
          setProduct(data.data);
        }).catch(error => console.error(error));
    }

    function loadPrices(id:number){
      setSelected(id);
    }

    
    let current:null|recordProduct = null;
    if (selected!==null&&product!=='loading'&&product!==null)
      current = product.find(x=>x.ID===selected)!;

    return <>
    <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
            <a className="navbar-brand" onClick={()=>navigate('/')}>ZdajLekko.pl</a>

            <Link to={'/nowe-konto'} className="small btn btn-xs  btn-primary fw-bold my-auto " style={{backgroundColor:'#f9844a',borderColor:'#f9844a'}}>Załóż konto!</Link>
        </div>
    </nav>
    <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
        <div className="col-md-5 p-lg-5 mx-auto my-5">
            <h1 className="display-4 font-weight-normal">Dlaczego ZdajLekko.pl?</h1>
            <p className="lead font-weight-normal">Poniźej przedstawimy kilka powodów dlaczego warto dołączyć do naszego <b>zamkniętego</b> grona!</p>
        </div>
        <div className="product-device box-shadow d-none d-md-block"></div>
        <div className="product-device product-device-2 box-shadow d-none d-md-block"></div>
    </div>

    <div className="d-md-flex flex-md-equal w-100">
      <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden order-0" style={{backgroundColor:'#f9844a'}}>
        <div className="my-3 py-3">
          <h2 className="display-5">Pytania PES z bazy CEM</h2>
          <p className="lead">Medycyna rodzinna (2024-2016), Pediatria (2024-2020), Otorynolaryngologia(2024-2020), Choroby wewnętrzne(2024-2020), Diabetologia(2024-2020)</p>
        </div>
        <div className="bg-light box-shadow mx-auto" style={{width:'80%',borderRadius:'21px 21px 0 0'}}>
            <img src="https://zdajlekko.pl/zdjecia/cem.png" className="img-fluid" alt="Baza pytań CEM" />
        </div>
      </div>
      <div className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden order-1">
        <div className="my-3 py-3">
          <h2 className="display-5">Kategorie i tagi</h2>
          <p className="lead">Pytania staramy się przypisać pod konkretne kategorie i tagi dla ułatwienia nauki. Przeglądaj i twórz egzaminy z tego czego się uczysz!</p>
        </div>
        <div className="bg-light box-shadow mx-auto" style={{width:'80%',borderRadius:'21px 21px 0 0'}}>
            <img src="https://zdajlekko.pl/zdjecia/kategorie.png" className="img-fluid" alt="Podział na kategorie i tagi" />
        </div>
        {/* <div className="bg-dark box-shadow mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div> */}
      </div>
    </div>

    

<div className="d-md-flex flex-md-equal w-100">
  <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden order-1 order-sm-0">
    <div className="my-3 p-3">
      <h2 className="display-5">Baza pytań autorskich i użytkowników</h2>
      <p className="lead">Z Medycyny Rodzinnej mamy ponad 1000 pytań autorskich. Ty również masz możliwość rozbudowywania tej bazy! Jeśli chcesz zaskoczyć innych przygotowujących! :-)</p>
    </div>
    <div className="bg-light box-shadow mx-auto" style={{width:'80%',borderRadius:'21px 21px 0 0'}}>
        <img src="https://zdajlekko.pl/zdjecia/autorskie.png" className="img-fluid" alt="Autorskie pytania" />
    </div>
  </div>
  <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden order-0 order-sm-1" style={{backgroundColor:'#f9844a'}}>
    <div className="my-3 py-3">
      <h2 className="display-5">Generator</h2>
      <p className="lead">Uczysz się tylko jednego działu? Skorzystaj z generatora i utwórz egzamin korzystając z podziału pytań na kategorie i tagi.</p>
    </div>
    <div className="bg-light box-shadow mx-auto" style={{width:'80%',borderRadius:'21px 21px 0 0'}}>
        <img src="https://zdajlekko.pl/zdjecia/generator.png" className="img-fluid" alt="Generator egzaminów" />
    </div>
  </div>
</div>

    

    <div className="d-md-flex flex-md-equal w-100">
      <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden" style={{backgroundColor:'#f9844a'}}>
        <div className="my-3 p-3">
          <h2 className="display-5">Pytania ulubione</h2>
          <p className="lead">Każde pytanie z bazy CEM lub autorskie może zostać oznaczone jako ulubione. Potem możesz utworzyć z nich egzamin.</p>
        </div>
        <div className="bg-light box-shadow mx-auto" style={{width:'80%',borderRadius:'21px 21px 0 0'}}>
            <img src="https://zdajlekko.pl/zdjecia/ulubione.png" className="img-fluid" alt="Ulubione pytania" />
        </div>
      </div>
      <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
        <div className="my-3 py-3">
        <h2 className="display-5">Pytania ze źródeł</h2>
        <p className="lead">Do każdego z pytań staramy się dodać informację na temat źródła informacji. Jeśli uczysz się z jednego źródła, możesz przeglądać pytania tylko z niego.</p>
        </div>
        <div className="bg-light box-shadow mx-auto" style={{width:'80%',borderRadius:'21px 21px 0 0'}}>
            <img src="https://zdajlekko.pl/zdjecia/zrodla.png" className="img-fluid" alt="Podział pytań na źródła" />
        </div>
      </div>
    </div>

    

<div className="d-md-flex flex-md-equal w-100">
  <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
    <div className="my-3 p-3">
      <h2 className="display-5">Wsparcie!</h2>
      <p className="lead">Posiadamy zamknięte grupy wsparcia, podzielone na specjalizacje! Rozmawiamy przede wszystkim na temat pytań i egzaminu, ale czasami również pomagamy pozbyć się stresu :-) Nie oszalejesz w trakcie nauki!</p>
    </div>
  </div>
  <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden" style={{backgroundColor:'#f9844a'}}>
    <div className="my-3 py-3">
      <h2 className="display-5">Pomożemy Ci zdać!</h2>
      <p className="lead">W grupie wsparcia i osobiście staramy się jak najbardziej Tobie pomóc osiągnąć cel i zdać! W sesji wiosennej 2025 z Medycyny Rodzinnej wszystkie osoby na podium to nasi <strong>BOHATERZY</strong> :-)</p>
    </div>
  </div>
</div>
<div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
    <div className="col-md-5 p-lg-5 mx-auto mt-5 pb-lg-0">
        <h1 className="display-4 font-weight-normal">Jaka specjalizacja Cię interesuje?</h1>
        <div className="lead font-weight-normal">
        {product===null||product==='loading'?
        <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>:
        <>
        <form>
        <div className="form-floating mt-1">
          <select className="form-select" id="specSelect" aria-label="Wybierz specjalizację, aby poznać ofertę." defaultValue={0} onChange={(e)=>loadPrices(parseInt(e.target.value))}>
            <option value="0">Wybierz!</option>
            {product.map(function(i){
              return <option value={i.ID} key={`spec-${i.ID}`}>{i.SpecName}</option>
            })}
          </select>
          <label htmlFor="specSelect">Wybierz specjalizację!</label>
        </div>
        </form>
        </>
        }
        </div>
      </div>
      <div className="row"></div>
      {product===null||product==='loading'?null:<>
        {isLoadingPrices===true?<div className="alert alert-info my-2 col-md-5 mx-auto"><div className="d-flex mt-3 align-items-center"><div className="spinner-border mt-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div><p className="ms-1">Pobieranie oferty dla specjalizacji <b>{current?.SpecName}</b>...</p></div></div>:null}
        {isLoadingPrices===false&&current!==null?
          (current.Prices!==null && current.Prices.length > 0) ?
          <>
          <div className="alert alert-info my-2 col-md-5 mx-auto">
            <span className="">Pakiet dostępu możesz zakupić po utworzeniu konta. Po zalogowaniu zakładka <b>Oferta</b>.</span>
          </div>
            <div className="row row-cols-1 row-cols-md-4 mb-3 text-center mt-3">{current.Prices?.map(function(i){
              return <DrawSpecPrice item={i} />
            })}</div>
          </>
          :<div className="alert alert-info" role="alert"><p>Niestety nie mamy jeszcze pakietów ofertowych dla tej specjalizacji. Jeśli chcesz się dowiedzieć czegoś więcej możesz do nas napisać na <a href="mailto:kontakt@zdajlekko.pl">kontakt@zdajlekko.pl</a>.</p></div>
          
        :null}
        </>
        }
        {/* <Form.Label column sm={2}>Specjalizacja</Form.Label>
                    <Col sm={10}>
                        <Form.Select aria-label="Wybierz specjalizację..." ref={selectedSpecID} onChange={specSelectChange} defaultValue={state.selectedSpecID!}>
                            <option value="0">Wybierz specjalizację...</option>
                            {state.productsList?.map(function(d, idx){
                                    return (<option value={d.ID} key={d.ID}>{d.SpecName}</option>)
                            })}
                        </Form.Select>
                    </Col> */}
      <div className=" pb-lg-5"></div>
</div>
<div className="container">
    <div className="row">
        <div className="col-12 text-center">
            <p><a href="/regulamin-2024-11-20.pdf" target='_blank' rel="noindex, nofollow" className="btn btn-default btn-sm">Regulaminu</a> 
              <span className="mx-2">|</span> 
               <a href="/polityka-prywatnosci.pdf" target='_blank' rel="noindex, nofollow" className="btn btn-default btn-sm">Polityki Prywatności</a></p>
        </div>
    </div>
</div>

    </>;
}